import React from 'react';
import './features.css';
import { AiFillSchedule} from 'react-icons/ai';
import {AiFillRead} from 'react-icons/ai';
import { BsFillCalculatorFill } from "react-icons/bs";
import {FiTarget } from "react-icons/fi";
import { GoLaw } from 'react-icons/go';
import { HiOutlineDocumentChartBar } from 'react-icons/hi2';
import { BsDot } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Card = (props) => {
  return (
    <div className="card">
      
      <div className="card-contents">
        <h3 className='card-title'>{props.title}</h3>
        <p className='card-content'>{props.description}</p>
      </div>
    </div>
  );
}



const Features = () => {
  

  
  let activeCardIndex = null;
  const navigate = useNavigate();

  const handleRedirect = (path) => {
    navigate(path);
    window.scroll(0,0);
  };
  return (
    <div className='features'>
      <Helmet>
      <meta charSet="utf-8" />
      
      <meta name="keywords" content="Viogi, računovodstvo, knjigovodstvo, poresko savetovanje, finansijsko planiranje, usluge, poslovanje" />
      <meta name="author" content="Vuk Stanić" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="canonical" href="https://viogi.rs" />
      <meta name="robots" content="index, follow" />
    </Helmet>

      <h1 className='naslov-kartica'>
        UNAPREDITE<br></br> 
        VAŠE POSLOVANJE
      </h1>
    <div className='cardlist'>
    <div  className='cards'>
    <p className='cards-items' onClick={() => handleRedirect("/Usluge/Osnivanje")}><AiFillSchedule className='icons'/><Card
      
      title="Osnivanje"
      description="Osnivanje privrednog društva ili preduzetničke radnje uz adekvatnu pomoć, prave informacije i rešavanje svake Vaše dileme..."
    />
    </p>
   <p className='cards-items' onClick={() => handleRedirect("/Usluge/Knjigovodstvo")
   } ><AiFillRead className='icons'/><Card
      
      title="Knjigovodstvo"
      description="Usluge računovodstva i knjigovodstva pružamo u skladu sa važećim državnim i međunarodnim standardima i propisima ..."
    /></p>
    <p className='cards-items' onClick={() => handleRedirect("/Usluge/Obracuni")}><BsFillCalculatorFill className='icons'/><Card
    
    title="Obračuni"
    description="Obračun zarada, vođenje kadrovskih evidencija i ostale kadrovske usluge takođe spadaju u delokrug našeg poslovanja ...​"
  /></p>
  </div>
  <div className='cards'>
  <p className='cards-items' onClick={() => handleRedirect("/Usluge/APR")}> <FiTarget className='icons'/><Card
      
      title="APR Usluge"
      description="Sve usluge pred Agencijom za Privredne Registre sprovodimo u saradnji sa advokatskim timom potpuno ispravno a brzo i efikasno..."
    />
    </p>
   <p className='cards-items' onClick={() => handleRedirect("/Usluge/BiznisPlan")}><HiOutlineDocumentChartBar className='icons'/><Card
      
      title="Biznis Plan"
      description="Nudimo uslugu izrade kvalitetnog biznis plana, prema Vašim zahtevima, potrebama i ciljevima ..."
    /></p>
    <p className='cards-items' onClick={() => handleRedirect("/Usluge/PravneUsluge")}><GoLaw className='icons'/><Card
    
    title="Pravne Usluge"
    description="U sklopu svojih usluga nudimo i adekvatno pružanje svih vrsta pravnih usluga vezanih za Vaše poslovanje, unapredjenje ili zaštitu istog ...​"
  /></p>
  </div>
  </div>
  <div className="opis" id="opis"></div>
  </div>
  )
}

export default Features