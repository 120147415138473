import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {Navbar} from './components/navbar/Navbar';
import {Usluge,Osnivanje,APR,Knjigovodstvo,Obracuni,BiznisPlan, PravneUsluge,O_Nama} from './pages';
import {Home} from './pages';
import { Footer } from './containers';
import Kontakt from './pages/Kontakt';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from '@vercel/analytics/react';
import {Menu} from './components/navbar/Navbar.jsx'
const App = () => {

  return (
    <div className='App'>
      <div className='back' />
      <SpeedInsights/>
      <Analytics/>
      <Router>
        <div className='gradient__bg'>
          
          <Navbar />

          <Routes>
            <Route path="/" element={<Home />} index />
            <Route path="/Kontakt" element={<Kontakt />} />
            <Route path="/Usluge" element={<Usluge />} />
            <Route path="/Usluge/Osnivanje" element={<Osnivanje />} />
            <Route path="/Usluge/APR" element={<APR />} />
            <Route path="/Usluge/Obracuni" element={<Obracuni />} />
            <Route path="/Usluge/Knjigovodstvo" element={<Knjigovodstvo />} />
            <Route path="/Usluge/BiznisPlan" element={<BiznisPlan />} />
            <Route path="/Usluge/PravneUsluge" element={<PravneUsluge />} />
            <Route path="/O_Nama" element={<O_Nama />} />
          </Routes>
          <Footer/>
        </div>
      </Router>
    </div>
  );
};

export default App;
