import React from 'react';
import './whatGPT3.css';
import { useState, useEffect,useRef } from 'react';
import video1 from '../../assets/video1.mp4'
import video2 from '../../assets/video2.mp4'
import thumb1 from '../../assets/thumb1.png'
import thumb2 from '../../assets/thumb2.png'
import { BiCaretLeft } from "react-icons/bi";
import { BiCaretRight } from "react-icons/bi";
import {motion } from "framer-motion"
import { Helmet } from 'react-helmet';


const WhatGPT3 = () => {
  const [videos, setVideos] = useState([
    { src: video2, thumbnail: thumb1 },
    
  ]);
  const [direction,setDirection]=useState(0)
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [thumbnailVisible, setThumbnailVisible] = useState(false);
  function previous(){
    if (currentVideoIndex===0){
      setCurrentVideoIndex(videos.length-1)
      return
    } 
    setCurrentVideoIndex(currentVideoIndex - 1)
  }
  function next(){
    if (currentVideoIndex===videos.length-1){
      setCurrentVideoIndex(0)
      return
    }
    setCurrentVideoIndex(currentVideoIndex + 1)
  }
  const variants={
    initial: direction =>{
      return{
        y: direction>0? 200: -200,
        opacity:0,
      }
    },
    animate:{
      y:0,
      opacity:1,
    },
    exit:direction =>{
      return{
        x: direction>0? -200: 200,
        opacity:0,
      }
    },
    
  }
  return (
    <div className="videos">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <motion.video  src={videos[currentVideoIndex].src} autoPlay={true} className='video' loop muted variants={variants}

      animate='animate'
      initial='initial'
      exit='exit'
      custom={direction} />

    {thumbnailVisible && <img src={videos[currentVideoIndex].thumbnail} />}
    {/* <button className='left_arrow' onClick={previous} ><BiCaretLeft/></button>
    <button className='right_arrow' onClick={next}><BiCaretRight/></button>
     */}
    </div>
  )
}

export default WhatGPT3
