import React from 'react';
import './Knjigovodstvo.css';
import { Helmet } from 'react-helmet';
import { Footer,Features } from './../containers';


const Knjigovodstvo = () => {
  return (
    <div className='osnivanje-container'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Računovodstvo Knjigovodstvo</title>
        <meta name="description" content="Spremni smo da svoje usluge u oblasti računovodstva i knjigovodstva prilagodimo svakom klijentu pojedinačno, u skladu sa delatnošću, načinu i obimu poslovanja. Pružamo usluge u skladu sa važećim standardima i propisima." />
        <meta name="keywords" content="Računovodstvo, Knjigovodstvo, vođenje poslovnih knjiga, analitičko knjigovodstvo, PDV evidencija, završni račun, platni promet, poreska uprava" />
        <meta name="author" content="Vuk Stanić" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="/Usluge/Knjigovodstvo" />
        <meta name="robots" content="index, follow" />
      </Helmet>
        <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />
      <div className='container'>

	  <div className='tekstualni_deo'>	
      <h1 className='naslov_os'>Računovodstvo <br/>Knjigovodstvo</h1>
      
		
				
      <h3 class="podnaslov_1">Spremni smo da svoje usluge u ovoj oblasti prilagodimo svakom klijentu pojedinačno, u skladu sa delatnošću, načinu i obimu poslovanja.</h3>

	  <div class="linija">
			<span class="linija">_______________________________________________________________</span>
		</div>
		
      <p className='pasus_1'>
      Usluge računovodstva i knjigovodstva pružamo u skladu sa važećim državnim i međunarodnim standardima i propisima, a u duhu osnovnog postulata svoje struke – savesno, stručno, odgovorno i precizno.
      </p>
      <br /><br />
      
      <ul className='lista'>
        
        <li className='delovi_liste'> Vođenje poslovnih knjiga : glavne knjige i pomoćne knjige, dnevnik,</li>
        <li className='delovi_liste'> Vođenje analitičkog knjigovodstva: kupci, dobavljači, osnovna sredstva, roba, proizvodnja, materija, gotovi proizvodi,</li>
        <li className='delovi_liste'> Kalkulacije maloprodajnih i veleprodajnih cena, vođenje KEP knjige,</li>
        <li className='delovi_liste'> Vođenje PDV evidencije, obračun PDV-a, izrada poreske prijave za PDV,</li>
        <li className='delovi_liste'> Vođenje deviznog poslovanja,  </li>
        <li className='delovi_liste'> Izrada završnog računa: bilans stanja, bilans uspeha, statistički aneks, poreski bilans i poreska prijava poreza na dobit, odnosno dohodak građana.</li>
        <li className='delovi_liste'> Platni promet (bezgotovinski platni promet I platni promet sa inostranstvom),</li>
        <li className='delovi_liste'> Poreska uprava (elektronska predaja poreske prijave, ucešćenje u poreskom postupku, preknjiženje, povraćaj poreza i td…)</li>
      </ul>
      </div>
      <div id='slika'>	
      <img src="https://www.nimi.rs/wp-content/uploads/2019/12/Usluga2S.jpg" className='knjigovodja' />
      </div>	
      </div>
      
      
    </div>
  );
};

export default Knjigovodstvo;
