import React from 'react';
import './O_nama.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Footer,Features } from './../containers';


const O_Nama = () => {
    const navigate = useNavigate();
    const handleRedirect = (path) => {
        navigate(path);
        window.scroll(0,0);
      };
  return (
    <div className='o_nama-container'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>O Nama - Agencija VIOGI</title>
        <meta
          name='description'
          content='Agencija VIOGI je osnovana 2014. godine. Naš uigrani tim može odgovoriti svim vašim zahtevima. Pružamo efikasnu, tačnu i pravovremenu uslugu.'
        />
        <meta name='keywords' content='Agencija VIOGI, usluge, efikasnost, tačnost, pravovremenost' />
        <meta name='author' content='Vuk Stanić' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <link rel="canonical" href="/O_Nama" />
        <meta name="robots" content="index, follow" />
      </Helmet>
        <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />
      <div className='container_onama'>

	  <div className='tekstualni_deo'>	
      <h1 className='naslov_os'>O nama</h1>
      
		
				
      <h3 class="podnaslov_1_onama">Agencija VIOGI  je osnovana 2014. godine. Čini je uigran  tim  koji može da odgovori svim vašim zahtevima .</h3>

	  <div class="linija">
			<span class="linija">_______________________________________________________________</span>
		</div>
		
      <p className='pasus_1_onama'>
      U svoje poslovanje ulažemo  visoko obrazovani kadar, kao i dugogodišnje iskustvo steknuto radom u državnim i privatnim preduzećima a još pre samog osnivanja agencije.    
     <br/>
     Tu smo za Vas da pružimo efikasnu, tačnu i pravovremenu uslugu.<br /><br />
     Naše prednosti u odnosu na druge: <br /><br />
      </p>
      
      <div className='list_i_slika'>
      <ul className='lista_onama'>
        
        <li className='delovi_liste_onama'> Dnevna ažurnost,</li>
        <li className='delovi_liste_onama'> Posvećenost klijentu</li>
        <li className='delovi_liste_onama'> Efikasno davanje saveta u borbi sa komplikovanim propisima i procedurama </li>
        <li className='delovi_liste_onama'> Online knjigovodstvo, po potrebi rad u vašem softveru</li>
        <li className='delovi_liste_onama'> Pružamo usluge klijetima sa sedištem iz drugih gradova </li>
        <li className='delovi_liste_onama'> Konsultantske  i savetodavne usluge su besplatne i uključene su u osnovnu cenu.</li>
        <li className='delovi_liste_onama'> Po zahtevu klijeta radimo mesečnu, kvartalnu ili polugodišnju  analizu poslovanja, što vam daje smernice kako da upravljate svojim biznisom</li>
        <li className='delovi_liste_onama'> Dugogodišnji rad u privredi nas opredeljuje da veoma dobro razumemo  sa kakvim problemima i prekama se suočavate i tu smo da Vam damo adekvatan savet i podršku.</li>
      </ul>
      <div>	
      {/*< img src={require("../assets/onm.jpg")} className='onm' />*/}
      </div>
      </div>
      <p className='pasus_1_onama'>
      Agencija VIOGI  je upisana u Registar pružalaca računovodstvenih usluga, na osnovu Rešenja o izdavanju dozvole za pružanje računovodstvenih usluga izdatog od strane Komore ovlašćenih revizora br. D-739-283/23. Rešenje možete pogledati <a className='resenje' href='https://drive.google.com/file/d/1n3s2A8W7SQ1Vl1_bLVOO4e6gGwebxW8P/view?usp=sharing'>ovde.</a>
      </p>
      </div>
      	

      </div>
      
      
    </div>
  );
};

export default O_Nama;
