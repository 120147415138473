import React from 'react';
import './BiznisPlan.css';
import { Helmet } from 'react-helmet';



const BiznisPlan = () => {
  return (
    <div className='osnivanje-container'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Biznis Plan</title>
        <meta name="description" content="Nudimo uslugu izrade kvalitetnog biznis plana, prema Vašim zahtevima, potrebama i ciljevima. Garantujemo da će biti u skladu sa svim očekivanjima i važećim propisima, uz blagovremeno i pravilno izveštavanje državnih ustanova." />
        <meta name="keywords" content="Biznis Plan, izrada biznis plana, poslovna ideja, investicioni program, startup kredit, nacionalna služba za zapošljavanje" />
        <meta name="author" content="Vuk Stanić" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="/Usluge/BiznisPlan" />
        <meta name="robots" content="index, follow" />
      </Helmet>
        <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />
      <div className='container'>

	  <div className='tekstualni_deo'>	
      <h1 className='naslov_os'>Biznis Plan</h1>
      
		
				
      <h3 class="podnaslov_1">Nudimo uslugu izrade kvalitetnog biznis plana, prema Vašim zahtevima, potrebama i ciljevima.
</h3>

	  <div class="linija">
			<span class="linija">_______________________________________________________________</span>
		</div>
		
      <p className='pasus_1'>
      Garantujemo da će biti u skladu sa svim očekivanjima i važećim propisima, uz blagovremeno i pravilno izveštavanje državnih ustanova.
      </p>
      <br /><br />
      
      <p className="pasusi">Biznis plan je metodološka obrada poslovne ideje koja pokazuje opravdanost njene realizacije. Služi kao orijentir za poslovanje i predstavlja proveru poslovne ideje “na papiru”.
      </p>
      <p className="pasusi">Prilikom određivanja cene ove usluge najvažniji faktor je namena ovih dokumenata tj. da li se radi o investicionom programu, 
o biznis planu za dobijanje startup kredita ili pak biznis plana za nacionalnu službu za zapošljavanje.
</p>
      <p className="pasusi">Naravno, cena usluge zavisi i od toga da li je potencijalni korisnik ove usluge korisnik još nekih  naših usluga.</p>
      </div>
      <div id='slika'>	
      <img src="https://www.nimi.rs/wp-content/uploads/2019/12/Usluga5S.jpg" className='digitron' />
      </div>	
      </div>
      
      
    </div>
  );
};

export default BiznisPlan;
