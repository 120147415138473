import React from 'react';
import './Obracuni.css';
import { Helmet } from 'react-helmet';



const Obracuni = () => {
  return (
    <div className='osnivanje-container'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Obracuni - Vaše kadrovske usluge</title>
        <meta
          name='description'
          content='Obračun zarada, vođenje kadrovskih evidencija i ostale kadrovske usluge spadaju u delokrug našeg poslovanja. Garantujemo da će biti u skladu sa svim očekivanjima i važećim propisima.'
        />
        <meta name='keywords' content='Obračun zarada, kadrovske evidencije, socijalno osiguranje, potvrde o zaradi' />
        <meta name='author' content='Vuk Stanić' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <link rel="canonical" href="/Usluge/Obracuni" />
        <meta name="robots" content="index, follow" />
      </Helmet>
        <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />
      <div className='container'>

	  <div className='tekstualni_deo'>	
      <h1 className='naslov_os'>Obracuni</h1>
      
		
				
      <h3 class="podnaslov_1">Obračun zarada, vođenje kadrovskih evidencija i ostale kadrovske usluge takođe spadaju u delokrug našeg poslovanja.</h3>

	  <div class="linija">
			<span class="linija">_______________________________________________________________</span>
		</div>
		
      <p className='pasus_1'>
      Garantujemo da će biti u skladu sa svim očekivanjima i važećim propisima, uz blagovremeno i pravilno izveštavanje državnih ustanova.
      </p>
      <br /><br />
      
      <ul className='lista'>
        
        <li className='delovi_liste'> Izrada ugovora o radu, o privremenim i povremenim poslovima, o dopunskom radu, o ugovoru o delu;
        </li>
        <li className='delovi_liste'> Prijave i odjave zaposlenih na obavezno socijalno osiguranje;
        </li>
        <li className='delovi_liste'> Overa zdravstvenih knjižica;
        </li>
        <li className='delovi_liste'> Izdavanje potvrda o visini zarade i drugih sličnih potvrda;
        </li>
        <li className='delovi_liste'> Izrada mesečnih i godišnjih obračunskih listića.
        </li>
      </ul>
      </div>
      <div id='slika'>	
      <img src="https://www.nimi.rs/wp-content/uploads/2019/12/Usluga3S.jpg" className='digitron' />
      </div>	
      </div>
      
      
    </div>
  );
};

export default Obracuni;
