import { useState, useEffect,useRef } from 'react';
import './navbar.css';
import './../../index.css'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png';
import { handleClick } from './../../containers'
import { redirect } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { NavLink, useLocation } from 'react-router-dom';

const Menu = () => {
    const width = window.screen.width;
    

  return (
    <>

    
    <nav className='navigacija'>
      <p><NavLink exact to="/"activeClassName="active">Početna</NavLink></p>
      <p><NavLink to="/O_nama" activeClassName="active">O nama</NavLink></p>
      <p><NavLink to="/Usluge" activeClassName="active"> Usluge</NavLink></p>
      <p><NavLink to="/Kontakt" activeClassName="active">Kontakt</NavLink></p>
    </nav>
    
    </>
  );
}


const Navbar = () => {
    
    const logoRef = useRef(null);
	const menuCon = useRef(null);
    const menuRef =useRef(null);
    const navbarRef =useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY);
            const width = window.screen.width;
            if (width > 1000) {
                if (window.scrollY >= 100) {
                    logoRef.current.style.width = '80px';
                    logoRef.current.style.height = '70px';
                    menuRef.current.style.marginLeft='20%'
					menuRef.current.style.marginTop='0%'
                } else if (window.screenY < 100) {
                    logoRef.current.style.width = '110px';
                    logoRef.current.style.height = '100px';
                    menuRef.current.style.marginLeft='22%'
					menuRef.current.style.marginTop='40px'
                }
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            
        };
    }, [scrolled]);

    return (
        
        <div className="gpt3__navbar" ref={navbarRef}>
        <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        </Helmet>
            <div className="gpt3__navbar-links">
                <button className='logo-dugme' >
                    <a href='/'>
                        <ul>
                            <li>
                                <div className="gpt3__navbar-links_logo" >
                                    <img src={logo} alt="logo" ref={logoRef} width="110px" height="100px" />
                                </div>
                            </li>
                            <li>
                                <div className='viogi'>VIOGI</div>
                            </li>
                        </ul>
                    </a>
                </button>

                <div className="gpt3__navbar-links_container" ref={menuRef}>
                    <Menu />
                </div>

            </div>
            <div className={`gpt3__navbar-menu ${menuOpen ? 'open' : ''}`}>
                <button
                    onClick={() => setMenuOpen(!menuOpen)}
                    className="hamburger-menu-button"

                >
                    {menuOpen ? <RiCloseLine /> : <RiMenu3Line />}
                </button>
                {menuOpen && (
                    <div className="gpt3__navbar-menu_container">
                        <div className="gpt3__navbar-menu_container-links">
                            <Menu />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export { 
Navbar,
Menu,
}
