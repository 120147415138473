import React from 'react';
import './footer.css';
import { FiChevronRight } from "react-icons/fi";
import { Helmet } from 'react-helmet';


const Footer = () => {

  return (
    <div  className='bottom-content'>
        <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
      </Helmet>
     <table className='btPage' cellSpacing="30">
      
  <tr className='naslovi'>
    <td className='naslov'>VIOGI</td>
    <td className='naslov'>Korisni linkovi</td>
    {/* <td className='naslov'>Pomoć</td> */}

  </tr>
  
  <td className='pom_linkovi'> 

    <tr className='link'><a href="/Usluge/Osnivanje"  className='linkovi'><FiChevronRight className='ikonica'/>Osnivanje</a></tr>
    <tr className='link'><a href="/Usluge/APR"   className='linkovi'><FiChevronRight className='ikonica'/>APR usluge</a></tr>
    <tr className='link'><a href="/Usluge/Obracuni"  className='linkovi'><FiChevronRight className='ikonica'/>Obracuni</a></tr>
    <tr className='link'><a href="/Usluge/Knjigovodstvo" className='linkovi'><FiChevronRight className='ikonica'/>Knjigovodstvo</a></tr>
    <tr className='link'><a href="/Usluge/BiznisPlan" className='linkovi'><FiChevronRight className='ikonica'/>BiznisPlan</a></tr>
    <tr className='link'><a href="/Usluge/PravneUsluge"  className='linkovi'><FiChevronRight className='ikonica'/>PravneUsluge</a></tr>
    <tr className='link'><a href="/O_Nama" className='linkovi'><FiChevronRight className='ikonica'/>O nama</a></tr>
  </td>
  <td  className='pom_linkovi'>
    <tr className='link' ><a href='https://apr.gov.rs/%d0%bf%d0%be%d1%87%d0%b5%d1%82%d0%bd%d0%b0.3.html' target="_blank"  className='linkovi'><FiChevronRight className='ikonica'/>APR</a></tr>
    <tr className='link'><a href='https://www.purs.gov.rs/' target="_blank"  className='linkovi'><FiChevronRight className='ikonica'/>Poreska uprava</a></tr>
    <tr className='link'><a href='https://fondzarazvoj.gov.rs/cir' target="_blank"  className='linkovi'><FiChevronRight className='ikonica'/>Fond za razvoj</a></tr>
    <tr className='link'><a href='https://www.nsz.gov.rs/live/trazite-posao/svi-poslovi' target="_blank"  className='linkovi'><FiChevronRight className='ikonica'/>Nacionalna služba za zapošljavanje</a></tr>
    <tr className='link'><a href='https://www.nbs.rs/internet/cirilica/index.html' target="_blank"  className='linkovi'><FiChevronRight className='ikonica'/>Narodna banka Strbije</a></tr>
    {/* <tr className='link'><a href='http://ras.gov.rs/' className='linkovi'><FiChevronRight className='ikonica'/>Razvojna agencija Srbije</a></tr> */}
    
  </td>
  {/* <td className='pom_linkovi'>
  <tr className='link'><a href='http://ras.gov.rs/' className='linkovi'><FiChevronRight className='ikonica'/>Razvojna agencija Srbije</a></tr>
  <tr className='link'><a href='http://ras.gov.rs/' className='linkovi'><FiChevronRight className='ikonica'/>Razvojna agencija Srbije</a></tr>
  <tr className='link'><a href='http://ras.gov.rs/' className='linkovi'><FiChevronRight className='ikonica'/>Razvojna agencija Srbije</a></tr>
  <tr className='link'><a href='http://ras.gov.rs/' className='linkovi'><FiChevronRight className='ikonica'/>Razvojna agencija Srbije</a></tr>
  <tr className='link'><a href='http://ras.gov.rs/' className='linkovi'><FiChevronRight className='ikonica'/>Razvojna agencija Srbije</a></tr>
  </td> */}
</table>

<hr/>
</div>
  )
}

export default Footer