import React from 'react';
import { Helmet } from 'react-helmet';
import { Footer, Features } from './../containers';
import './Osnivanje.css';
const Osnivanje = () => {
  return (
    <div className='osnivanje-container'>
      <Helmet>
        <title>Osnivanje i Registracija firme - Vaša stručna podrška u osnivanju</title>
        <meta name='description' content='Vodimo Vas kroz postupak osnivanja firme uz stručnu podršku i savetovanje. Pružamo sveobuhvatne usluge tokom celog postupka osnivanja i registracije.' />
        <meta name='keywords' content='Osnivanje firme, Registracija firme, Pravne forme, Delatnosti, Porezi, Ovlašćeni direktor, PDV, Fiskalna kasa, Zapošljavanje' />
        <meta name='author' content='Vuk Stanić' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <link rel="canonical" href="/Usluge/Osnivanje" />
        <meta name="robots" content="index, follow" />
      </Helmet>
        <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />
      <div className='container'>

	  <div className='tekstualni_deo'>	
      <h1 className='naslov_os'>Osnivanje i<br/> Registracija <br/>firme</h1>
      
		
				
      <h3 class="podnaslov_1">Vodimo Vas kroz postupak osnivanja uz set standardnih ili pak dodatnih usluga.</h3>

	  <div class="linija">
			<span class="linija">_______________________________________________________________</span>
		</div>
		
      <p className='pasus_1'>
        Osnivanje privrednog društva ili preduzetničke radnje uz adekvatnu pomoć, prave informacije, rešavanje svake Vaše dileme i pružanje potpuno obrazloženih odgovora na sva pitanja može se sprovesti bez ikakvih komplikacija i bez nepotrebnog odugovlačenja.
      </p>
      <br /><br />
      <p className='podnaslov'>Sveobuhvatno savetovanje tokom celog postupka:</p>
      <br />
      <ul className='lista'>
        
        <li className='delovi_liste'> Izbor pravne forme&nbsp;kroz davanje odgovora na pitanja da li je konkretnom slučaju povoljnije osnovati radnju ili privredno društvo,</li>
        <li className='delovi_liste'> Izrada dokumentacije za registraciju&nbsp;(osnivački akti za privredna društva, odluka o imenovanju i ovlašćenju direktora, OP obrazac, registraciona prijava, potrebne uplatnice za APR),</li>
        <li className='delovi_liste'> Definisanje pretežne delatnosti&nbsp;kod preduzetnika, a kod privrednih društava i svih ostalih delatnosti,</li>
        <li className='delovi_liste'> Informacije o fiksnim troškovima poslovanja&nbsp;(porez na dohodak ili dobit, obavezni socijalni doprinosi osnivača I direktora, porezi i doprinosi za zaposlene radnike),</li>
        <li className='delovi_liste'> &nbsp;Upućivanje u PDV,</li>
        <li className='delovi_liste'> Posedovanje fiskalne kase&nbsp;(ko mora imati fiskalnu kasu a ko ne),</li>
        <li className='delovi_liste'> Savetovanje o zapošljavanju&nbsp;(trenutnim olakšicama, programima za zapošljavanja I sl. )</li>

      </ul>
      </div>
      <div id='slika'>	
      <img src={require("../assets/knjigovodja.png")} className='knjigovodja' />
      </div>	
      </div>
   
    </div>
  );
};

export default Osnivanje;
