import React from 'react'
import emailjs, { sendForm } from 'emailjs-com';
import './Kontakt.css'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const Kontakt = () => {
  const navigate = useNavigate();
  const handleRedirect = (path) => {
    navigate(path);
    window.scroll(0,0);
    
  };
  function sendEmail(e) {
    alert("Uspešno poslat mail.")
    e.preventDefault();

    emailjs.sendForm('viogi_offjswj', 'template_k3crpok', e.target, 'I9mkzFsgsVms0VF1A')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }
    return (
        <div className='osnivanje-container'>
        <Helmet>
        <meta charSet='utf-8' />
        <title>Javite Nam Se</title>
        <meta
          name='description'
          content='Kontaktirajte nas ako želite da pokrenete sopstveni biznis ili Vam treba savet i podrška za Vašu firmu.'
        />
        <meta name='keywords' content='kontakt, biznis, podrška, savet, firma' />
        <meta name='author' content='Vuk Stanić' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <link rel="canonical" href="/Kontakt" />
        <meta name="robots" content="index, follow" />
      </Helmet>

        <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />
      <div className='container_kontakt'>

	  <div className='naslov_podnaslov_kontakt'>
      <h1 className='kontakt_naslov'>JAVITE NAM SE</h1>
      
		
				
      <h3 className="podnaslov_kontakt">Želite da pokrenete sopstveni biznis ili Vam treba savet i podrška za Vašu<br/> firmu. Ne dozvolite da Vaš posao čeka, kontaktirajte nas..</h3>
        </div>
	  <h2 class="kagencija">
			Knjigovodstvena agencija <br/>VIOGI ČAČAK
		</h2>
		
    <table className='tabela_kontakt'>
<tbody>
<tr>
<td className="tabela_polja_kontakt_he">&nbsp;Adresa: </td>
<td className="tabela_polja_kontakt">&nbsp;Rajićeva 7 lamela C, lokal br. 1</td>
</tr>
<tr>
<td className="tabela_polja_kontakt_he">&nbsp;Telefon:</td>
<td className="tabela_polja_kontakt">&nbsp;
063 8526270</td>
</tr>
<tr>
<td className="tabela_polja_kontakt_he">&nbsp;Email:</td>
<td className="tabela_polja_kontakt">&nbsp;d.vidakovic73@gmail.com</td>
</tr>
<tr>
<td className="tabela_polja_kontakt_he">&nbsp;PIB:</td>
<td className="tabela_polja_kontakt">&nbsp;108490682</td>
</tr>
<tr>
<td className="tabela_polja_kontakt_he">&nbsp;MB:</td>
<td className="tabela_polja_kontakt">&nbsp;63488852</td>
</tr>
</tbody>
</table>
<div class="t_slika">
<img src="..\assets\t2.png" ></img>
</div>
<h2 class="kagencija_podnaslov">Pišite nam</h2>
<section class="formcarry-container">
  <form action="mailto:vuk.stanic2002@gmail.com" method="POST" enctype="multipart/form-data" onSubmit={sendEmail
  }>
    
    <div class="formcarry-block">
    <label for="fc-generated-1-name"></label>
      <input type="text" name="name" id="fc-generated-1-name" placeholder="Ime i prezime" />
    </div>
  	
    <div class="formcarry-block">
    <label for="fc-generated-1-email"></label>
      <input type="email" name="email" id="fc-generated-1-email" placeholder="Email" />
    </div>
  	
    <div class="formcarry-block">
    <label for="fc-generated-1-message"></label>
      <textarea name="message" id="fc-generated-1-message" placeholder="Vaša poruka"></textarea>
    </div>
  	
    <div class="formcarry-block">  
      <button type="submit" onClick={handleRedirect("/Kontakt")}>Pošaljite</button>
    </div>
  
  </form>
</section>


      </div>
      	
     
      
      

    </div>
    
    );
};

export default Kontakt