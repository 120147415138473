import React from 'react';
import './PravneUsluge.css';
import { Helmet } from 'react-helmet';



const PravneUsluge = () => {
  return (
    <div className='osnivanje-container'>
      <Helmet>
        <title>Pravne Usluge - Pružamo sveobuhvatne pravne usluge za Vaše poslovanje</title>
        <meta name='description' content='Pružamo sve vrste pravnih usluga vezanih za poslovanje, unapređenje i zaštitu Vašeg biznisa. U saradnji sa uspešnim Advokatskim timom pružamo zastupanje i savete u sudskim, upravnim i drugim postupcima.' />
        <meta name='keywords' content='Pravne usluge, Advokatski tim, Zastupanje, Poslovno pravo, Radno pravo, Postupci, Privredna društva' />
        <meta name='author' content='Vuk Stanić' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <link rel="canonical" href="/Usluge/PravneUsluge" />
        <meta name="robots" content="index, follow" />
      </Helmet>
        <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />
      <div className='container'>

	  <div className='tekstualni_deo'>	
      <h1 className='naslov_os'>Pravne <br/>Usluge</h1>
      
		
				
      <h3 class="podnaslov_1">
U sklopu svoji usluga nudimo i adekvatno pružanje svih vrsta pravnih usluga vezanih za Vaše poslovanje, unapredjenje ili zaštitu istog.
</h3>

	  <div class="linija">
			<span class="linija">_______________________________________________________________</span>
		</div>
		
      <p className='pasus_pravneusluge'>
      
U saradnji sa uspešnim Advokatskim timom koji svoju delatnu aktivnost bazira na privrednom i radnom pravu,  uspešnom zastupanju privrednih društava u svim vrstama postupaka, kako sudskim tako i vansudskim, upravnim i svim vrstama postupaka pred državnim i drugim organima, kao i pred Agencijom za Privredne Registre, u sklopu svoji usluga nudimo i adekvatno pružanje svih vrsta pravnih usluga vezanih za Vaše poslovanje, unapredjenje ili zaštitu istog.      </p>
      <br /><br />
      </div>
      <div id='slika'>	
      <img src="https://www.nimi.rs/wp-content/uploads/2019/12/Usluga6S.jpg" className='digitron' />
      </div>	
      </div>
      
      
    </div>
  );
};

export default PravneUsluge;
