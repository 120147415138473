import React from 'react';
import { Footer,Features } from './../containers';
import './Usluge.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Card = (props) => {
  return (
    <div className="card">
      
      <div className="card-contents">
        <h3 className='card-title-usluga'>{props.title}</h3>
        <p className='card-content-usluga'>{props.description}</p>
      </div>
    </div>
  );
}
const Usluge = () => {
  const navigate = useNavigate();
  window.scroll(0,0);
  const handleRedirect = (path) => {
    navigate(path);
    window.scroll(0,0);
  };
  return (
    <div>
      <Helmet>
        <title>Osnivanje i Registracija firme - Viogi Agencija</title>
        <meta name="description" content="Vodimo Vas kroz postupak osnivanja uz set standardnih ili pak dodatnih usluga." />
        <meta name="keywords" content="osnivanje, registracija firme, standardne usluge, dodatne usluge" />
        <meta name="author" content="Vuk Stanić" />
        <link rel="canonical" href="/Usluge" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <br /><br />
      <br /><br />

				
      <div className='usluge'>
      <br /><br />
      <br /><br />
      <div className='naslov_i_opis_usluga' >
      <h1 className="naslov_usluga">Knjigovodstvene usluge</h1>	
      
				
			
				<p className="opis_usluga">Nudimo precizno, profesionalno i kvalitetno obavljanje knjigovodstvenih usluga prilagođeno specifičnostima svakog klijenta.</p>
      </div>
		
				
    <div className='cardlist'>
      <div  className='cards'>
    <p className='cards-items-usluge' onClick={() => handleRedirect("/Usluge/Osnivanje")} >
      
      <img src='https://www.nimi.rs/wp-content/uploads/2019/12/Usluga1S.jpg' className='slike_za_kartice'/>
      
      <Card className='kartice_usluga'
      
      title="Osnivanje"
      description="Vodimo Vas kroz postupak osnivanja uz set standardnih ili pak dodatnih usluga."
    />
    </p>
   <p className='cards-items-usluge' onClick={() => handleRedirect("/Usluge/Knjigovodstvo")} ><img src='https://www.nimi.rs/wp-content/uploads/2019/12/Usluga2S.jpg' className='slike_za_kartice'/><Card className='kartice_usluga' 
      
      title="Knjigovodstvo"
      description="Spremni smo da svoje usluge u ovoj oblasti prilagodimo svakom klijentu pojedinačno, u skladu sa delatnošću, načinu i obimu poslovanja."
    /></p>
    <p className='cards-items-usluge' onClick={() => handleRedirect("/Usluge/Obracuni")}><img src='https://www.nimi.rs/wp-content/uploads/2019/12/Usluga3S.jpg' className='slike_za_kartice'/><Card className='kartice_usluga' 
    
    title="Obračuni"
    description="Obračun zarada, vođenje kadrovskih evidencija i ostale kadrovske usluge takođe spadaju u delokrug našeg poslovanja.​"
  /></p>
  </div>
  <div className='cards'>
  <p className='cards-items-usluge' onClick={() => handleRedirect("/Usluge/APR")}><img src='https://www.nimi.rs/wp-content/uploads/2019/12/Usluga4S.jpg' className='slike_za_kartice'/><Card className='kartice_usluga' 
      
      title="APR"
      description="Našim postojećim i potencijalnim klijentima obezbeđujemo popust na naknadu za pružanje ovih usluga."
    />
    </p>
   <p className='cards-items-usluge' onClick={() => handleRedirect("/Usluge/BiznisPlan")} ><img src='https://www.nimi.rs/wp-content/uploads/2019/12/Usluga5S.jpg' className='slike_za_kartice'/><Card className='kartice_usluga' 
      
      title="Biznis Plan"
      description="Nudimo uslugu izrade kvalitetnog biznis plana, prema Vašim zahtevima, potrebama i ciljevima."
    /></p>
    <p className='cards-items-usluge' onClick={() => handleRedirect("/Usluge/PravneUsluge")}><img src='https://www.nimi.rs/wp-content/uploads/2019/12/Usluga6S.jpg' className='slike_za_kartice'/><Card className='kartice_usluga' 
    
    title="Pravne Usluge"
    description="U sklopu svoji usluga nudimo i adekvatno pružanje svih vrsta pravnih usluga vezanih za Vaše poslovanje, unapredjenje ili zaštitu istog.​"
  /></p>
  </div>
  </div>

  </div>
  </div>
  
  );
};

export default Usluge;
