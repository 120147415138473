import React from 'react';
import './APR.css';
import { Helmet } from 'react-helmet';



const APR = () => {
  return (
    <div className='osnivanje-container'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>APR Usluge</title>
        <meta name="description" content="APR Usluge - Pružanje usluga u vezi postupaka pred Agencijom za Privredne Registre. Popust na naknadu za klijente. Registracija promena kod privrednih društava, preduzetnika, pravne forme, likvidacija i brisanje." />
        <meta name="keywords" content="APR, Agencija za Privredne Registre, usluge, registracija promena, privredna društva, preduzetnici, pravna forma, likvidacija, brisanje" />
        <meta name="author" content="Vuk Stanić" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="/Usluge/APR" />
        <meta name="robots" content="index, follow" />
      </Helmet>
        <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />
      <div className='container'>

	  <div className='tekstualni_deo'>	
      <h1 className='naslov_os'>APR Usluge</h1>
      
		
				
      <h3 class="podnaslov_1">Našim postojećim i potencijalnim klijentima obezbeđujemo popust na naknadu za pružanje ovih usluga.</h3>

	  <div class="linija">
			<span class="linija">_______________________________________________________________</span>
		</div>
		
      <p className='pasus_1'>
      U skladu sa važećim zakonskim propisima sve usluge u vezi postupaka pred Agencijom za Privredne Registre sprovodimo u saradnji sa advokatskim timom, postupajući u skladu sa zakonskim odredbama, potpuno ispravno a brzo i efikasno.
      </p>
      <br /><br />
      
      <ul className='lista'>
        
        <li className='delovi_liste'> Registracija promena kod privrednih društava (promena poslovnog imena, zakonskog zatupnika, sedišta, pravne forme, pretežne delatnosti),</li>
        <li className='delovi_liste'> Registracija promena kod preduzetnika (prijava početka obavljanej delatnosti, promena naziva, poslovnog sedišta, pretežne delatnosti, upis ili promena poslovođe ovlašcenog za zastupanje, privremeni prekid obavaljanja delatnosti),</li>
        <li className='delovi_liste'> Promena pravne forme (preduzetnik u privredno društvo),</li>
        <li className='delovi_liste'> Likvidacija privrednog društva,</li>
        <li className='delovi_liste'> Brisanje preduzetnika.</li>
      </ul>
      </div>
      <div id='slika'>	
      <img src="https://www.nimi.rs/wp-content/uploads/2019/12/Usluga4S.jpg" className='knjigovodja' />
      </div>	
      </div>
      
      
    </div>
  );
};

export default APR;
