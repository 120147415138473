import React from 'react'
import {Article,Brand,CTA,Feature, Navbar} from './../components'
import { Footer,Blog,Features,Possibility,WhatGPT3 } from './../containers'
import './../App.css'
import {Usluge} from './../pages'
import { BrowserRouter as Router, Route,Routes, Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';



const Home = () => {
    return (
      
      <div className='App'>
              <Helmet>
              <meta name='Classification' content='Accounting'/>
              <meta name='copyright' content='Viogi'/>
              <link rel="canonical" href="https://viogi.rs"/>
              <meta name='subject' content='Knjigovodstvo, Računovodstvo, APR Usluge, Biynis plan, Pravne Usluge'/>
              <meta 
              name="description" 
              content="Viogi je stručna računovodstvena firma sa sedištem u Čačku, koja pruža raznovrsne usluge knjigovodstva, poreskog savetovanja i finansijskog planiranja. Naš posvećeni tim stručnjaka obezbeđuje visokokvalitetne usluge i podršku za vaše poslovanje u oblasti računovodstva u Čačku i šire." />
              <title>Viogi Knjigovodstvo i Računovodstvo - Unapredite Vaše Poslovanje</title>
      </Helmet>
        {/* <Brand/> */}
        <WhatGPT3/>
        <Features/>
        {/* <Possibility/> */}
        {/* <CTA/>
        <Blog/> */}
        
          
      </div>
    )
  }

  export default Home